<template>
  <div class="recipe-list container">
    <div class="filter-block">
      Filter: <b-input v-model="query" v-on:keyup="checkForRun" />
      <b-button squared variant="dark" v-on:click="runFilter">Filter</b-button> <b-button squared variant="light" v-on:click="query = ''; runFilter();">Clear</b-button>
    </div>
    <table class="table table-striped" v-if="recipes.length > 0">
      <thead class="thead-dark">
        <tr>
          <th scope="col" width="50%">Name</th>
          <th scope="col" width="20%">Source</th>
          <th scope="col" width="10%">Units of Measure</th>
          <th scope="col" width="20%">Categories</th>
        </tr>
      </thead>
      <tr v-for="(recipe,idx) in filteredRecipes" :key="idx">
        <td><router-link :to="'/recipe/' + recipe._id" >{{recipe.name}}</router-link><div style="float: right; margin-left: 15px;"><b-badge variant="danger" v-if="recipe.conversion.complete==false">incomplete</b-badge></div><RecipeFlags v-model="recipe.flags" :readonly="true" /></td>
        <td>{{ recipe.source }}</td>
        <td>{{ systemsOfMeasure(recipe).join(', ') }}</td>
        <td><CategoryList :categories="recipe.categories" @change="categoryChanged" /></td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapDebugActions } from '@/lib/debug';


//import json from '@/json/recipes.json';
import CategoryList from '@/components/CategoryList.vue';
import RecipeFlags from '@/components/RecipeFlags.vue';
import _ from 'lodash';

export default {
  name: 'Recipe',
  props: { filter: String },
  data() {
    return {
      query: (this.filter)?this.filter:'',
    }
  },
  components: {
    CategoryList,
    RecipeFlags
  },
  mounted () {
    this.debugMessage ('<<MOUNTED>>');
    this.debugObject ({params: this.$route.params});
    this.fetchRecipes(this.query);
  },
  watch:{
    $route (){
      this.fetchRecipes(this.query);
    }
  },
  methods: {
    ...mapActions({ "fetchRecipes": "recipe/fetchRecipes" }),
    ...mapDebugActions,
    categoryChanged(category) {
      this.query = '?categories=' + category;
      this.$emit('input', this.query);
      this.fetchRecipes(this.query);
    },
    systemsOfMeasure(recipe) {
      return recipe.ingredients.map( x => x.system ).filter( (x, idx, self) => { return self.indexOf(x) === idx } );
    },
    checkForRun(e) {
      if (e.keyCode === 13) {
        this.runFilter();
      }
    },
    runFilter() {
      this.$emit('input', this.query);
      this.fetchRecipes(this.query);
    }
  },
  computed: {
    ...mapGetters({ "recipes": "recipe/recipes" }),
    filteredRecipes() {
      let t = _.orderBy(this.recipes, 'name', 'asc');
      return t;
    },
  }
}
</script>

<style scoped>
.recipe-flags {
  display: block;
  float: right;
}
.filter-block {
  margin-bottom: 30px;
}
</style>
